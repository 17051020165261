import React from "react";
import { useTranslation } from "react-i18next";

export default function Portfolio() {
  const { t } = useTranslation(['portfilio']);
  const web = t('web', { returnObjects: true });
  // console.log(web)
  return (
    <div className="section" id="portfolio">
      <div className="container">
        <div className="row">
          <div className="col-md-6 ml-auto mr-auto">
            <div className="h4 text-center mb-4 title">{t('portfilio')}</div>
            <div className="nav-align-center">
              <ul className="nav nav-pills nav-pills-primary" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="tab" href="#web-development" role="tablist" title={t('webprogramming')}>
                    <i className="fa fa-globe" aria-hidden="true"></i>
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#graphic-design" role="tablist" title={t('projects')}>
                    <i className="fa fa-picture-o" aria-hidden="true"></i>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#Photography" role="tablist" title={t('photos')}>
                    <i className="fa fa-terminal" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="tab-content gallery mt-5">
          <div className="tab-pane active" id="web-development">
            <div className="ml-auto mr-auto">
              <div className="row">
                
              {web && web.map((v, i) => {
          return (
            <div key={i} className="col-md-6">
                    <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                      <a href={v.link}>
                        <figure className="cc-effect"><img src={ v.img } alt={ v.title }/>
                          <figcaption>
                            <div className="h4">{ v.title }</div>
                            <p>{ v.text }</p>
                          </figcaption>
                        </figure>
                      </a>
                    </div>
                  </div>
          )
        })}

                  
                
              </div>
            </div>
            <div className="tab-pane" id="graphic-design" role="tabpanel">
              <div className="ml-auto mr-auto">
                <div className="row">
                  
                </div>
              </div>
            </div>
            <div className="tab-pane" id="Photography" role="tabpanel">
              <div className="ml-auto mr-auto">
                <div className="row">
                  

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      );
}