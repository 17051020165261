import React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

export default function Header() {
  const { t } = useTranslation(['header']);
  const menu = t('header', { returnObjects: true });
  const changeLanguage = (lng) => {
    localStorage.setItem('lang',lng);
    i18n.changeLanguage(lng); // ენის შეცვლა
  };

  return (
    <header id="main">
      <div className="profile-page sidebar-collapse">
        <nav className="navbar navbar-expand-lg fixed-top navbar-transparent bg-primary" color-on-scroll="400">
          <div className="container">
            <div className="navbar-translate">
              <a className="navbar-brand" href="!#" rel="tooltip noreferrer">CV</a>
              <button className="navbar-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </button>
            </div>
            <div className="collapse navbar-collapse justify-content-end" id="navigation">
              <ul className="navbar-nav">
                {menu && (menu.map((i, v) => {
                  return <li key={v} className="nav-item"><a className="nav-link smooth-scroll" href={`#${i.url}`} >{i.name}</a></li>
                })
                )}
                <li className="nav-item "><a className="nav-link smooth-scroll "  href='#RamaziMaisashvili' >
                <img style={{"width":"25px"}} src="./images/ge.png" alt="In Georgian" onClick={()=>changeLanguage('ge')} />&nbsp;
                <img style={{"width":"25px"}} src="./images/en.png" alt="In Engish" onClick={()=>changeLanguage('en')}/>&nbsp;
                <img style={{"width":"25px"}} src="./images/ru.png" alt="In Russian" onClick={()=>changeLanguage('ru')}/>
                </a></li>

              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}